import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { FormNS } from "./form";
import { GroupNS } from "./group";
import { fieldLinkSchema } from "./link";
import { SectionNS } from "./section";

export const FieldNS = buildNamespace({
  system,
  entity: "Field",
} as const);

export const fieldTypeValue = [
  "text",
  "multilineText",
  "number",
  "currency",
  "choice",
  "info",
  "date",
  "checkbox",
  "address",
  "yesNo",
  "phoneNumber",
  "email",
  "file",
  "appointment",
] as const;
export type FieldType = (typeof fieldTypeValue)[number];
export const fieldTypeLabel: Record<FieldType, string> = {
  text: "Text",
  multilineText: "Multi-line Text",
  number: "Number",
  currency: "Currency",
  choice: "Multiple Choice",
  info: "Information",
  date: "Date",
  checkbox: "Checkbox",
  address: "Address",
  yesNo: "Yes No",
  phoneNumber: "Phone Number",
  email: "Email",
  file: "File",
  appointment: "Appointment",
};
export const fieldTypeSchema = z.enum(fieldTypeValue);

export const meetingTypeValue = ["inPerson", "telephoneConsult"] as const;
export type MeetingType = (typeof meetingTypeValue)[number];
export const meetingTypeLabel: Record<MeetingType, string> = {
  inPerson: "In-person",
  telephoneConsult: "Phone meeting",
};
export const meetingTypeSchema = z.enum(meetingTypeValue);

export const fieldFieldSchema = z.object({
  name: z.string(),
  type: z.string(),
  possibleValues: z.array(z.string()).optional(),
});

export const fieldLayoutSchema = z.object({
  id: z.string(),
  providerId: z.string(),
  name: z.string(),
  parentId: z.string().optional(),
  parentName: z.string().optional(),
  parentProviderId: z.string().optional(),
});

export const FieldSchema = z.object({
  uri: FieldNS.schema,
  operationId: z.string(),

  formUri: FormNS.schema,
  sectionUri: SectionNS.schema,
  groupUri: GroupNS.schema.optional(),
  order: z.string().regex(/[a-z:]/),

  label: z.string(),
  description: z.string().optional(),
  hint: z.string(),
  type: fieldTypeSchema,
  options: z.array(z.object({ value: z.string(), label: z.string() })),
  mandatory: z.boolean(),
  allowCustomResponse: z.boolean().optional().nullable(),

  updatedAt: z.string(),
  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),

  layout: fieldLayoutSchema.optional(),
  field: fieldFieldSchema.optional(),

  links: z.array(fieldLinkSchema).optional(),

  allowCopyFromFieldUri: FieldNS.schema.optional(),

  availableStaffIds: z.array(z.string()).optional(),
  duration: z.number().optional(),
  availability: z
    .array(
      z.object({
        day: z.number(),
        fromTime: z.object({
          hour: z.number(),
          minute: z.number(),
        }),
        toTime: z.object({
          hour: z.number(),
          minute: z.number(),
        }),
        enabled: z.boolean(),
      }),
    )
    .optional(),
  timezone: z.string().optional(),
  bufferTime: z.number().optional(),
  minimumNotice: z.number().optional(),
  meetingType: meetingTypeSchema.optional(),
});

export const mapToFieldType = (matterFieldType?: string): FieldType => {
  switch (matterFieldType) {
    case "DateTime":
      return "date";
    case "Combobox":
    case "Checkbox":
      return "choice";
    default:
      return "text";
  }
};

export const fieldResetValues = {
  text: "",
  multilineText: "",
  email: "",
  date: "",
  number: NaN,
  currency: null,
  choice: null,
  yesNo: null,
  checkbox: [],
  address: null,
  phoneNumber: null,
  file: null,
  info: null,
} as const;
