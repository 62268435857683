import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { TeamNS } from "./team";
import { UserNS } from "./user";
import { arrayComparer, entriesOf } from "@smart/itops-utils-basic";

export const FormNS = buildNamespace({
  system,
  entity: "Form",
} as const);

export const formCategoryValue = ["lead", "matter"] as const;
export const formCategoryLabel: Record<
  (typeof formCategoryValue)[number],
  string
> = { matter: "Matter", lead: "Lead" };
export const formCategorySchema = z.enum(formCategoryValue);

export type FormCategory = (typeof formCategoryValue)[number];

const aiFillSettingsSchema = z.object({
  allowAiFill: z.boolean().optional(),
  sectionTitle: z.string().optional(),
  fileUploadLabel: z.string().optional(),
  fileUploadDescription: z.string().optional(),
});

export const FormSchema = z.object({
  uri: FormNS.schema,
  operationId: z.string(),

  teamUri: TeamNS.schema,
  createdBy: UserNS.schema,
  createdAt: z.string(),
  updatedBy: UserNS.schema,
  updatedAt: z.string(),

  title: z.string(),
  response: z.string(),
  active: z.boolean().optional(),
  creationStatus: z.string().optional().nullable(),
  source: z.string().optional(),
  matterId: z.string().optional(),
  aiFillSettings: aiFillSettingsSchema.optional(),

  category: formCategorySchema,

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),
});

export const defaultAIFillSettings = {
  sectionTitle: "Autofill Assistant",
  fileUploadLabel: "**Welcome!**",
  fileUploadDescription: `You can upload any relevant legal documents here to help us pre-populate the form fields. \n\nThis step is optional - skip if you'd rather fill it out manually.`,
};

export const familyProQuestionnaireFormUri = "family-pro-questionnaire-form";
export const familyProIntakeEnquiryFormUri = "family-pro-intake-enquiry";
export const familyProParentingFormUri = "family-pro-parenting-questionnaire";
export const familyProParentingAndPropertyFormUri =
  "family-pro-parenting-and-property";
export const familyProPropertyFormUri = "family-pro-property";
export const familyProParentingExclCurrSitFormUri =
  "family-pro-parenting-excluding-current-situation-questionnaire";
export const familyProParentingAndPropertyExclCurrSitFormUri =
  "family-pro-parenting-and-property-excluding-current-situation";
export const familyProPropertyExclCurrSitFormUri =
  "family-pro-property-excluding-current-situation";

export const isFamilyProFormUri = (uri: string | undefined) => {
  if (!uri) return false;
  return [
    familyProIntakeEnquiryFormUri,
    familyProParentingFormUri,
    familyProParentingAndPropertyFormUri,
    familyProPropertyFormUri,
    familyProParentingExclCurrSitFormUri,
    familyProParentingAndPropertyExclCurrSitFormUri,
    familyProPropertyExclCurrSitFormUri,
  ].includes(uri);
};

export type FamilyProFormUri =
  | typeof familyProIntakeEnquiryFormUri
  | typeof familyProParentingFormUri
  | typeof familyProParentingAndPropertyFormUri
  | typeof familyProPropertyFormUri
  | typeof familyProParentingExclCurrSitFormUri
  | typeof familyProParentingAndPropertyExclCurrSitFormUri
  | typeof familyProPropertyExclCurrSitFormUri;

export type FamilyProQuestionnaireType =
  | "intakeEnquiry"
  | "parenting"
  | "parentingProperty"
  | "property"
  | "parentingExclCurrSit"
  | "parentingPropertyExclCurrSit"
  | "propertyExclCurrSit";

type FamilyProStepsName =
  | "generalSteps"
  | "propertySteps"
  | "parentingSteps"
  | "currentSituationSteps"
  | "otherSteps";

type FamilyProSteps = {
  [key in FamilyProStepsName]?: number[];
};

export const extractFamilyProQuestionnaireType = (
  uri: FamilyProFormUri,
): FamilyProQuestionnaireType => {
  switch (uri) {
    case familyProIntakeEnquiryFormUri:
      return "intakeEnquiry";
    case familyProParentingFormUri:
      return "parenting";
    case familyProParentingAndPropertyFormUri:
      return "parentingProperty";
    case familyProPropertyFormUri:
      return "property";
    case familyProParentingExclCurrSitFormUri:
      return "parentingExclCurrSit";
    case familyProParentingAndPropertyExclCurrSitFormUri:
      return "parentingPropertyExclCurrSit";
    case familyProPropertyExclCurrSitFormUri:
      return "propertyExclCurrSit";
    default:
      return "intakeEnquiry";
  }
};

export const questionnaireTypeToFamilyProFormUriMap: Record<
  FamilyProQuestionnaireType,
  FamilyProFormUri
> = {
  intakeEnquiry: familyProIntakeEnquiryFormUri,
  parenting: familyProParentingFormUri,
  parentingProperty: familyProParentingAndPropertyFormUri,
  property: familyProPropertyFormUri,
  parentingExclCurrSit: familyProParentingExclCurrSitFormUri,
  parentingPropertyExclCurrSit: familyProParentingAndPropertyExclCurrSitFormUri,
  propertyExclCurrSit: familyProPropertyExclCurrSitFormUri,
};

const generalSteps = [1, 2, 3, 4];
const propertySteps = [5, 6, 7, 8, 9, 10, 11];
const parentingSteps = [5];
const currentSituationSteps = [1, 2, 3, 4];
const otherSteps = [12];

export const familyProStepsMapping: Record<
  FamilyProQuestionnaireType,
  FamilyProSteps
> = {
  intakeEnquiry: { generalSteps },
  parenting: {
    generalSteps,
    currentSituationSteps,
    parentingSteps,
    otherSteps,
  },
  parentingProperty: {
    generalSteps,
    propertySteps,
    currentSituationSteps,
    parentingSteps,
    otherSteps,
  },
  property: {
    generalSteps,
    propertySteps,
    currentSituationSteps,
    otherSteps,
  },
  parentingExclCurrSit: {
    generalSteps,
    parentingSteps,
    otherSteps,
  },
  parentingPropertyExclCurrSit: {
    generalSteps,
    propertySteps,
    parentingSteps,
    otherSteps,
  },
  propertyExclCurrSit: {
    generalSteps,
    propertySteps,
    otherSteps,
  },
};

export const getFamilyProQuestionnaireTypeFromSteps = (
  stepsObject: FamilyProSteps,
): FamilyProQuestionnaireType | undefined => {
  const nonEmptySteps = Object.entries(stepsObject).filter(
    ([, steps]) => steps?.length,
  );

  if (!nonEmptySteps.length) return;

  const found = entriesOf(familyProStepsMapping).find(([, targetSteps]) => {
    const targetStepsEntries = Object.entries(targetSteps);
    if (targetStepsEntries.length !== nonEmptySteps.length) return false;

    return targetStepsEntries.every(([stepsName, steps]) => {
      const correspondingSteps = stepsObject[stepsName as FamilyProStepsName];
      return (
        correspondingSteps &&
        arrayComparer(steps, "equalTo", correspondingSteps)
      );
    });
  });

  return found?.[0];
};

export type FormAIFillSettings = z.infer<typeof aiFillSettingsSchema>;
